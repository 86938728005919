<template>
  <div class="TXmap">
    <div id="container" :style="{ 'height': isPrint ? '800px' : '500px' }"></div>
    <!-- 操作面板 -->
    <div class="operation" v-if="!maptype">
      <el-button tpye="primary" size="medium" icon="el-icon-refresh" @click="startMove">重头开始移动</el-button>
      <el-button tpye="primary" size="medium" icon="el-icon-video-play" @click="pauseMove">暂停移动</el-button>
      <el-button tpye="primary" size="medium" icon="el-icon-video-pause" @click="resumeMove">恢复移动</el-button>
      <el-button tpye="primary" size="medium" @click="measure('distance')" id="distance" class="inactive">{{ distanceTxt
      }}</el-button>
    </div>
    <div class="toFresh" v-if="maptype == 'position'">
      <div>实时刷新位置：</div>
      <el-input v-model="seconds" placeholder="请输入刷新秒数" @change="getInput" style="width: 100px; margin: 0px 10px">
        <span slot="suffix">秒</span>
      </el-input>
      <el-checkbox v-model="checked"></el-checkbox>
    </div>
  </div>
</template>

<script>
import { reverseCoord } from "@/api/common/common";
import { GetCarNewLocation } from "@/api/auditInfo/certified/index";
export default {
  props: {
    isPrint: false,
    list: {
      //传进来的列表
      type: Array,
      default() {
        return [];
      },
    },
    maptype: {
      type: String,
      default() {
        return ""; // 不传显示轨迹 传入'position'则显示一个位置
      },
    },
    posinfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      marker: null, //地图句柄
      speed: 5000, //移动速度
      seconds: 20, // 秒数
      checked: false,
      timer: null,
      posInfoLast: {},
      positionMap: "",
      map: "",
      measureTool: "",
      distanceTxt: '开始测距'
    };
  },
  methods: {
    // 关闭销毁组件内容
    destroyElement() {
      this.map.destroy();
      this.map = "";
      this.$destroy();
    },
    init() {
      //初始化地图
      let map = new TMap.Map("container", {
        // zoom: 13,
        center: this.center,
        renderOptions: {
          preserveDrawingBuffer: true, // 保留地图的渲染缓冲 默认false
        },
      });
      this.measureTool = new TMap.tools.MeasureTool({
        map: map,
      });
      this.map = map;

      let polylineLayer = new TMap.MultiPolyline({
        map, // 绘制到目标地图
        // 折线样式定义
        styles: {
          style_blue: new TMap.PolylineStyle({
            color: "#3777FF", //线填充色
            width: 4, //折线宽度
            borderWidth: 2, //边线宽度
            borderColor: "#FFF", //边线颜色
            lineCap: "round", //线端头方式
          }),
        },
        geometries: [
          {
            styleId: "style_blue",
            paths: this.path,
          },
        ],
      });

      this.marker = new TMap.MultiMarker({
        map,
        styles: {
          "car-down": new TMap.MarkerStyle({
            width: 30,
            height: 60,
            anchor: {
              x: 30 / 2,
              y: 60 / 2,
            },
            faceTo: "map",
            rotate: 180,
            src: "https://res.gts56.com/fzkj/web/front/YSB_PC/trackCar.png",
          }),
          start: new TMap.MarkerStyle({
            width: 25,
            height: 35,
            anchor: { x: 16, y: 32 },
            src: "https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/start.png",
          }),
          end: new TMap.MarkerStyle({
            width: 25,
            height: 35,
            anchor: { x: 16, y: 32 },
            src: "https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/end.png",
          }),
          point: new TMap.MarkerStyle({
            width: 18, // 点标记样式宽度（像素）
            height: 25, // 点标记样式高度（像素）
            // "src": 'https://img2.baidu.com/it/u=394445974,1839509839&fm=26&fmt=auto&gp=0.jpg',  //图片路径
            src: require("@/assets/image/2.png"), //图片路径
            //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
            anchor: { x: 16, y: 32 },
          }),
        },
        geometries: [
          {
            id: "car",
            styleId: "car-down",
            position: new TMap.LatLng(0, 0),
          },
          {
            id: "start",
            styleId: "start",
            position: this.start,
          },
          {
            id: "end",
            styleId: "end",
            position: this.end,
          },
          ...this.points,
        ],
      });

      var bounds = new TMap.LatLngBounds();
      //判断标注点是否在范围内
      this.points.forEach(function (item) {
        //若坐标点不在范围内，扩大bounds范围
        if (bounds.isEmpty() || !bounds.contains(item.position)) {
          bounds.extend(item.position);
        }
      });
      //设置地图可视范围
      this.map.fitBounds(bounds, {
        padding: 100, // 自适应边距
      });

      //初始化mark标记点点击事件
      var infoWindow = new TMap.InfoWindow({
        map: map,
        position: new TMap.LatLng(0, 0),
        offset: { x: 0, y: -32 }, //设置信息窗相对position偏移像素，为了使其显示在Marker的上方
      });
      infoWindow.close(); //初始关闭信息窗关闭
      var infoWindow1 = new TMap.InfoWindow({
        map: map,
        position: new TMap.LatLng(0, 0),
        offset: { x: 0, y: -10 }, //设置信息窗相对position偏移像素，为了使其显示在Marker的上方
      });
      infoWindow1.close(); //初始关闭信息窗关闭
      //监听标注点击事件
      this.marker.on("click", function (evt) {
        infoWindow1.close(); //初始关闭信息窗关闭
        //不处理起点和重点
        if (evt.geometry.styleId == "start" || evt.geometry.styleId == "end")
          return;
        //经纬度逆解析
        let params = {
          lat: evt.geometry.position.lat,
          lng: evt.geometry.position.lng,
        };
        reverseCoord(params).then((res) => {
          //设置infoWindow
          infoWindow.open(); //打开信息窗
          infoWindow.setPosition(evt.geometry.position); //设置信息窗位置
          //设置信息窗内容
          infoWindow.setContent(`<div>
        <p style="text-align:left;font-size:15px;margin:10px 0;"><span style="font-weight:700">地址：</span>${res.data.address}</p>
        <p style="text-align:left;font-size:15px;margin:10px 0;"><span style="font-weight:700">经纬度：</span>${evt.geometry.position.lng}-${evt.geometry.position.lat}</p>
        <p style="text-align:left;font-size:15px;margin:10px 0;"><span style="font-weight:700">时间：</span>${evt.geometry.properties.time}</p>
        </div>`);
        });
      });
      map.on('click', (evt) => {
        infoWindow.close(); //初始关闭信息窗关闭
        // 获取click事件返回的poi信息
        //经纬度逆解析
        let poi = evt.poi
        if (poi) {
          let params = {
            lat: poi.latLng.lat,
            lng: poi.latLng.lng,
          };
          reverseCoord(params).then((res) => {
            //设置infoWindow
            infoWindow1.open(); //打开信息窗
            infoWindow1.setPosition(poi.latLng); //设置信息窗位置
            //设置信息窗内容
            infoWindow1.setContent(`<div>
        <p style="text-align:left;font-size:15px;margin:10px 0;"><span style="font-weight:700">地址：</span>${res.data.address}</p>
        <p style="text-align:left;font-size:15px;margin:10px 0;"><span style="font-weight:700">经纬度：</span>${poi.latLng.lng}-${poi.latLng.lat}</p>
        </div>`);
          });
        } else {
          infoWindow.close()
        }
      });
      this.startMove();
    },
    measure() {
      this.distanceTxt = '开始测距';
      this.measureTool.measureDistance().then((res) => {
        this.distanceTxt = '结束测距';
      });
    },
    initPOsition() {
      let that = this;
      var center = new TMap.LatLng(this.posInfoLast.lat, this.posInfoLast.lon); //设置中心点坐标
      //初始化地图
      this.positionMap = new TMap.Map("container", {
        center,
      });
      //初始化infoWindow
      var infoWindow = new TMap.InfoWindow({
        map: that.positionMap,
        position: center, //设置信息框位置
        offset: { x: 0, y: -52 },
        content: `<div>
        <div style="text-align:left;font-size:15px;margin:10px 0;display:flex;"><span style="font-weight:700;display:inline-block;">地址：</span> <div style="display:flex;flex-wrap:wrap"> ${this.posInfoLast.adr}</div></div>
        <p style="text-align:left;font-size:15px;margin:10px 0;"><span style="font-weight:700">经纬度：</span>${this.posInfoLast.lat}-${this.posInfoLast.lon}</p>
        <p style="text-align:left;font-size:15px;margin:10px 0;"><span style="font-weight:700">车牌号：</span>${this.posinfo.carNumber}</p>
        <p style="text-align:left;font-size:15px;margin:10px 0;"><span style="font-weight:700">最后定位时间：</span>${this.posInfoLast.utc}</p>
        </div>`, //设置信息框内容
      });
      var marker = new TMap.MultiMarker({
        map: that.positionMap,
        styles: {
          // 点标记样式
          point: new TMap.MarkerStyle({
            width: 30,
            height: 60,
            anchor: {
              x: 30 / 2,
              y: 60 / 2,
            },
            faceTo: "map",
            rotate: 0,
            src: "https://res.gts56.com/fzkj/web/front/YSB_PC/trackCar.png",
          }),
        },
        geometries: [
          // 点标记数据数组
          {
            styleId: "point",
            position: center,
          },
        ],
      });

      marker.on("click", function () {
        infoWindow.open();
      });
    },
    // 刷新位置
    freshLocation() {
      const loading = this.$loading({
        lock: true,
        text: "位置实时刷新中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      GetCarNewLocation({ carNumber: this.posinfo.carNumber })
        .then((res) => {
          this.posInfoLast = res.data || {};
          this.positionMap.destroy();
          this.initPOsition();
        })
        .finally(() => {
          loading.close();
        });
    },
    getInput(val) {
      if (Number(val) < 20) {
        this.$message.warning("刷新时间不能低于20秒");
        this.seconds = 20;
        return;
      }
      if (Number(val) > 600) {
        this.$message.warning("刷新时间不能高于600秒");
        this.seconds = 600;
        return;
      }
      if (this.checked) {
        clearInterval(this.timer);
        this.timer = setInterval(() => {
          this.freshLocation();
        }, this.seconds * 1000);
      }
    },
    //重头开始移动
    startMove() {
      this.marker.moveAlong(
        {
          car: {
            path: this.path,
            speed: this.speed,
          },
        },
        {
          autoRotation: true,
        }
      );
    },
    //停止移动
    pauseMove() {
      this.marker.pauseMove();
    },
    //重新开始
    resumeMove() {
      this.marker.resumeMove();
    },
  },
  created() {
    if (this.maptype == "position") {
      this.posInfoLast = this.posinfo;
    }
  },
  mounted() {
    this.maptype == "position" ? this.initPOsition() : this.init();
  },
  computed: {
    //轨迹路线
    path() {
      if (this.maptype == "position") return;
      return this.list.map((item) => {
        return new TMap.LatLng(item.Lat, item.Lng);
      });
    },
    //标注点路线
    points() {
      if (this.maptype == "position") return;
      let arr = [];
      this.list.forEach((item, index) => {
        arr.push({
          id: index + 2,
          styleId: "point",
          position: new TMap.LatLng(item.Lat, item.Lng),
          properties: {
            //自定义属性
            time: item.GPSTime,
          },
        });
      });
      return arr;
    },
    //地图中心
    center() {
      if (this.path.length == 0 || this.maptype == "position") return;
      return this.path[parseInt(Number(this.path.length) / 2)];
    },
    //起点
    start() {
      if (this.path.length == 0 || this.maptype == "position") return;
      return this.path[0];
    },
    //终点
    end() {
      if (this.path.length == 0 || this.maptype == "position") return;
      return this.path[this.path.length - 1];
    },
  },
  watch: {
    checked(val) {
      if (val) {
        this.timer = setInterval(() => {
          this.freshLocation();
        }, this.seconds * 1000);
      } else {
        clearInterval(this.timer);
      }
    },
  },
  destroyed() {
    if (this.timer) clearInterval(this.timer);
  },
};
</script>

<style lang="scss">
.TXmap {
  #container {
    height: 500px;
  }

  .operation {
    width: 100%;
    margin: 30px 0;
    z-index: 99999;
    border-radius: 5px;
    background: white;
    display: flex;
    justify-content: center;
  }

  .toFresh {
    width: 100%;
    margin: 30px 0;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 22px;
    font-weight: bold;

    .el-input__suffix {
      display: flex;
      align-items: center;
      font-weight: bold;
      color: #606266;
    }

    .el-input__inner {
      height: 30px;
    }

    .el-checkbox__inner {
      width: 20px;
      height: 20px;
      display: inline-block;
    }

    .el-checkbox__inner::after {
      top: 2px;
      left: 7px;
      width: 5px;
      height: 10px;
    }
  }
}
</style>
